import React from 'react';
import "animate.css/animate.min.css"
import SBDStyle from './SBoardDescription.module.css'
import Cover from '../assets/sb_mod_pack.png'
import SBoardRelatedCards from "./SBoardRelatedCards"
import SB_Footer from './SB_Description_Footer'

class SBModifierPackDescription extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className={SBDStyle.container}>
                <div style={{color: '#0f0f0f', background: '#f0f0f0'}}>
                    <div className={SBDStyle.titleContainer} style={{color: '#0f0f0f'}}>
                        <h2>Modifier pack</h2>
                        <h3>4 modifiers in one pack</h3>
                    </div>
                    <div style={{textAlign:"center", background: '#f0f0f0'}}>
                        <img src={Cover} className={SBDStyle.banner} style={{boxShadow: 'none', maxHeight: '372px', height: 'auto'}}/>
                        <div style={{height: "16px"}}/>
                    </div>
                    <div style={{textAlign:"center", background: '#f0f0f0'}}>
                        <p className={SBDStyle.slogan}>Can't stop patching</p>
                        <div style={{height: "30px"}}/>
                    </div>
                    <div className={SBDStyle.cartArea}>
                        <div className={SBDStyle.cartAreaContent}>
                            <div>
                                <p style={{color: '#6f747f', fontWeight: 800}}>Bundle</p>
                                <p style={{fontSize: '1.8rem', textTransform: 'uppercase', fontWeight: 800}}>Modifier Pack</p>
                                <p style={{color: '#6f747f', fontWeight: 300}}>A $66 value</p>
                                <div className={SBDStyle.currPrice}>US $39 (40% off)</div>
                            </div>          
                            <div className={SBDStyle.buttonWrapper} style={{textAlign:'center'}}>
                                <p style={{paddingTop: '0.6rem'}}>Must use with S-Board!</p>
                                <button onClick={this.props.addToCart} className={SBDStyle.addToCartButton}>Add To Cart</button>
                            </div>
                        </div>
                    </div>
                </div>
                <SBoardRelatedCards currentItem="S-Board Modifier Pack"/>
                <SB_Footer getTrial={this.props.getTrial}/>
            </div>   
        )
    }
}

export default SBModifierPackDescription